'use strict';

var updateMiniCart = true;

module.exports = function () {
    $('#minicart').on('shown.bs.modal', function () {
    });

    $('.minicart').on('count:update', function (event, count) {

        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
            document.querySelector('.minicart').dispatchEvent(new CustomEvent('count:update', { detail: count }));
        }
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        $('.couponCodeBox').css('display', 'flex');
    });

    $('.minicart').on('click touchstart', function (event) {
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .miniCart-wrap.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .miniCart-wrap').addClass('show');
                return;
            }

            $('.minicart .miniCart-wrap').addClass('show');
            $('body').spinner().start();
            $.get(url, function (data) {
                $('.minicart .miniCart-wrap').empty();
                $('.minicart .miniCart-wrap').append(data);
                updateMiniCart = false;
                // Calcule a altura da área visível da tela
                let viewportHeight = window.innerHeight;

                // Aplique essa altura ao modal
                let modal = document.querySelector('.modal-content');
                modal.style.height = viewportHeight + 'px';
                modal.style.maxHeight = viewportHeight + 'px';
                $('#minicart').modal('show');
                $.spinner().stop();
            });
        } else if (count===0) {
            event.stopPropagation()
        }
        setInterval(() => {
            $(".modal-backdrop").remove();
        }, 20);

        if ($('.coupon-code').length > 0) {
            $('.couponCodeBox').css('display', 'none');
        }

        $('.promo-code-form-minicart').submit(function (e) {
            e.preventDefault();
            $.spinner().start();
            $('.coupon-missing-error').hide();
            $('.coupon-error-message').empty();
            if (!$('.coupon-code-field').val()) {
                $('.promo-code-form-minicart .form-control').addClass('is-invalid');
                $('.promo-code-form-minicart .form-control').attr('aria-describedby', 'missingCouponCode');
                $('.coupon-missing-error').show();
                $.spinner().stop();
                return false;
            }
            var $form = $('.promo-code-form-minicart');
            $('.promo-code-form-minicart .form-control').removeClass('is-invalid');
            $('.coupon-error-message').empty();
            $('body').trigger('promotion:beforeUpdate');

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.error) {
                        $('.promo-code-form-minicart .form-control').addClass('is-invalid');
                        $('.promo-code-form-minicart .form-control').attr('aria-describedby', 'invalidCouponCode');
                        $('.coupon-error-message').empty().append(data.errorMessage);
                        $('body').trigger('promotion:error', data);
                    } else {
                        $('.couponCodeBox').css('display', 'none');
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);
                        $('body').trigger('promotion:success', data);
                    }
                    $('.coupon-code-field').val('');
                    $.spinner().stop();
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
            return false;
        });

        $('body').on('click', '.remove-coupon', function (e) {
            e.preventDefault();

            var couponCode = $(this).data('code');
            var uuid = $(this).data('uuid');
            var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
            var $productToRemoveSpan = $('.coupon-to-remove');

            $deleteConfirmBtn.data('uuid', uuid);
            $deleteConfirmBtn.data('code', couponCode);

            $productToRemoveSpan.empty().append(couponCode);
        });

        $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
            e.preventDefault();

            var url = $(this).data('action');
            var uuid = $(this).data('uuid');
            var couponCode = $(this).data('code');
            var urlParams = {
                code: couponCode,
                uuid: uuid
            };

            url = appendToUrl(url, urlParams);

            $('body > .modal-backdrop').remove();

            $.spinner().start();
            $('body').trigger('promotion:beforeUpdate');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('.coupon-uuid-' + uuid).remove();
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                },
                error: function (err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        });
    });


    

    $('#minicart').on('hidden.bs.modal', function () {
        $('.minicart .miniCart-wrap').removeClass('show');
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .miniCart-wrap').removeClass('show');
        }
    });

    $('body').on('change', '.minicart .quantity', function () {

        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });

    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
